/* eslint-disable filenames/match-regex, filenames/match-exported */

import { ThemeProvider } from '@actinc/dls/components/ThemeProvider';
import AlertContextProvider from '@actinc/dls/context/AlertContext/provider';
import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BridgeContext, BridgeContextProvider } from 'encourage-ecosystem-lib';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import 'react-virtualized/styles.css';

import BridgeListener from '~/components/BridgeListener';
import CmsProviderComponent from '~/components/CmsProviderComponent';
import GlobalStyles from '~/components/GlobalStyles';
import LtiProvider from '~/components/LtiProvider';
import RefreshToken from '~/components/RefreshToken';
import ENV from '~/constants/ENV';
import { ApolloClientProviderComponent } from '~/context/ApolloClient';
import FeatureFlagProviderComponent from '~/context/FeatureFlagsHandler/FeatureFlags';
import { MaintenanceProviderComponent } from '~/context/Maintenance';
import { PendoProvider } from '~/context/Pendo';
import { ReactNativeFeaturesProviderComponent } from '~/context/ReactNativeFeatures';
import { ScholarshipDataProviderComponent } from '~/context/Scholarships/ScholarshipData';
import { createEmotionCache } from '~/helpers/material';
import composeComponents from '~/helpers/react/composeComponents';
import useMarketingCampaignId from '~/hooks/useMarketingCampaignId';
import PageTransitionLayout from '~/layouts/PageTransitionLayout';
import WithAppRefresher from '~/providers/WithAppRefresher';
import WithDebugMenu from '~/providers/WithDebugMenu';
import WithGlobalUtilities from '~/providers/WithGlobalUtilities';
import WithSmartAppBanner from '~/providers/WithSmartAppBanner';
import '~/styles/global.css';
import theme from '~/styles/theme';
import DebugThemeProvider from '~/styles/theme/DebugThemeProvider';

const clientSideEmotionCache = createEmotionCache();

const AppProviders = composeComponents([
  // Components added here will wrap every page of the app in reverse order
  // i.e. [.length-1] wraps [.length-2] wraps [.length-3] and so on
  WithGlobalUtilities,
  WithAppRefresher,
  WithDebugMenu,
  WithSmartAppBanner,
  ReactNativeFeaturesProviderComponent,
  PendoProvider,
  MaintenanceProviderComponent,
  FeatureFlagProviderComponent,
  LtiProvider,
  ApolloClientProviderComponent,
  ScholarshipDataProviderComponent,
  CmsProviderComponent,
  BridgeContextProvider as unknown as React.FC<{ children: React.ReactNode }>,
]);

const CLASSNAME_ALERTS_CONTAINER = 'notistackAlertContainerClass';
const CLASSNAME_ALERT = 'notistackAlertClass';

interface IMyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const App: React.FC<IMyAppProps> = ({ Component, emotionCache = clientSideEmotionCache, pageProps }: IMyAppProps): React.ReactElement<unknown> => {
  const { setMarketingCampaignId } = useMarketingCampaignId();
  const router = useRouter();

  useEffect((): void => {
    // Remove the server-side injected CSS
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }

    if (ENV.GTM_ID && ENV.GTM_ID !== '<GTM_ID_HERE>') {
      TagManager.initialize({ gtmId: ENV.GTM_ID });
    }
  }, []);

  setMarketingCampaignId();

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
      </Head>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <AlertContextProvider
            classes={{
              containerRoot: CLASSNAME_ALERTS_CONTAINER,
              root: CLASSNAME_ALERT,
            }}
          >
            <DebugThemeProvider>
              <CssBaseline />

              <AppProviders>
                <>
                  <GlobalStyles classNameAlert={CLASSNAME_ALERT} classNameAlertsContainer={CLASSNAME_ALERTS_CONTAINER} />
                  <BridgeListener />
                  <BridgeContext.Consumer>
                    {({ state }) => {
                      if (state.sendStringMessage) {
                        return (
                          <>
                            <PageTransitionLayout key={router.pathname}>
                              <Component {...pageProps} />
                            </PageTransitionLayout>
                            <RefreshToken />
                          </>
                        );
                      }

                      return null;
                    }}
                  </BridgeContext.Consumer>
                </>
              </AppProviders>
            </DebugThemeProvider>
          </AlertContextProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </CacheProvider>
  );
};

export default App;
